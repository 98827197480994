import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MeetLogin from "./pages/MeetLogin";
import MeetHome from "./pages/MeetHome";
import MeetIn from "./pages/MeetIn";
import MeetOut from "./pages/MeetOut";
// import TokenSetter from "./TokenSetter";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<MeetLogin />} />
        <Route path='/' element={<MeetLogin />} />
        <Route path='/start' element={<MeetIn />} />
        <Route path='/home' element={<MeetHome />} />
        <Route path='/out' element={<MeetOut />} />
        {/* <Route path='/set_token' element={<TokenSetter />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
