import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
  LocalUser,
  RemoteUser,
  useRemoteVideoTracks,
} from "agora-rtc-react";
import axios from "axios";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import meet_up_logo from "../assets/images/meet_up_logo.png";

function MeetHome() {
  const [isLocal, setLocal] = useState(false);
  const [calling, setCalling] = useState(true);
  const isConnected = useIsConnected();
  const [appId, setAppId] = useState("");
  const [channel, setChannel] = useState("");
  const [token, setToken] = useState("");
  const [shouldJoin, setShouldJoin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://vc.harpan.co.za/credentials.json"
        );
        const data = response.data;
        // console.log("data:========", data);
        if (data && data.app_id && data.channel_name && data.app_token) {
          setAppId(data.app_id);
          setChannel(data.channel_name);
          setToken(data.app_token);
          setShouldJoin(true);
        }
      } catch (error) {
        console.error("Error fetching the config file:", error);
      }
    };
    fetchData();
  }, []);

  const generateUid = () => Math.floor(Math.random() * 65535);
  // console.log(appId, "===", channel, "====", token);
  useJoin(
    {
      appid: appId,
      channel: channel,
      token: token || null,
      uid: generateUid(),
    },
    shouldJoin // Join conditionally based on shouldJoin state
  );

  // Local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // Remote users
  const remoteUsers = useRemoteUsers();

  useEffect(() => {
    if (isConnected) {
      setLocal(true);
      console.log("Connected to the channel.");
    } else {
      console.error("Not connected to the channel.");
    }
  }, [isConnected]);

  useEffect(() => {
    if (localMicrophoneTrack) {
      localMicrophoneTrack
        .setEnabled(micOn)
        .then(() => {
          console.log(`Microphone is ${micOn ? "enabled" : "disabled"}.`);
        })
        .catch((error) => {
          console.error("Error enabling/disabling microphone:", error);
        });
    }
  }, [localMicrophoneTrack, micOn]);

  useEffect(() => {
    if (localCameraTrack) {
      localCameraTrack
        .setEnabled(cameraOn)
        .then(() => {
          console.log(`Camera is ${cameraOn ? "enabled" : "disabled"}.`);
        })
        .catch((error) => {
          console.error("Error enabling/disabling camera:", error);
        });
    }
  }, [localCameraTrack, cameraOn]);

  useEffect(() => {
    remoteUsers.forEach((user) => {
      if (user.audioTrack) {
        user.audioTrack.play();
      }
      if (user.videoTrack) {
        console.log(`Playing video for user ${user.uid}`);
        user.videoTrack.play(`remote-video-${user.uid}`);
      } else {
        console.log(`No video track for user ${user.uid}`);
      }
    });
  }, [remoteUsers]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setCalling(false);
    navigate(`/`);
  };
  return (
    <div className='start-meeting-wrap'>
      <div className='start-meeting-inner-wrap'>
        <div className='metting-members'>
          {/* Use this class when one use is logged in - other-user-meet-view as user (meet-users-count-1) in this class metting-inner-members*/}
          {/* Use this class when two use is logged in - meet-users-count-2 in this class metting-inner-members */}
          <div
            className={`metting-inner-members ${
              remoteUsers.length === 1
                ? "other-user-meet-view"
                : remoteUsers.length > 1
                ? `other-user-meet-view meet-users-count-${remoteUsers.length}`
                : ""
            }`}>
            <LocalUser
              cameraOn={cameraOn}
              micOn={micOn}
              videoTrack={localCameraTrack}
              cover={img_user_profile}
              className='logged-in-user-view self-login-user'>
              <div className='mice-activetion'>
                <i
                  className={`fa-solid ${
                    micOn ? "fa-microphone" : "fa-microphone-slash"
                  }`}></i>
              </div>
            </LocalUser>
            {remoteUsers.map((user) => (
              <div className='logged-in-user-view' key={user.uid}>
                <div className='mice-activetion'>
                  <i className='fa-solid fa-microphone'></i>
                  <i className='fa-solid fa-microphone-slash'></i>
                </div>
                <div className='user'>
                  <div
                    id={`remote-video-${user.uid}`}
                    className='remote-video'></div>
                  <RemoteUser
                    cover={img_user_profile}
                    user={user}
                    className='logged-in-user-profile-video'
                  />
                  <div className='logged-user-name'>{user.uid}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='meeting-actions-keys'>
          <div className='inner-keys-featurs'>
            <ul className='time-link'>
              <li>11:35 PM</li>
              <li>zav-eiqy-nhd</li>
            </ul>
            <div className='video-views-actions'>
              <div className='meet-video-actions'>
                <a href='#' className='mice-btn' onClick={() => setMic(!micOn)}>
                  <i
                    className={`mice-on fa-solid ${
                      micOn ? "fa-microphone" : "fa-microphone-slash"
                    }`}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title={
                      micOn ? "Turn off microphone" : "Turn on microphone"
                    }></i>
                </a>
                <a
                  href='#'
                  className='video-btn'
                  onClick={() => setCamera(!cameraOn)}>
                  <i
                    className={`video-on fa-solid ${
                      cameraOn ? "fa-video" : "fa-video-slash"
                    }`}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title={
                      cameraOn ? "Turn off camera" : "Turn on camera"
                    }></i>
                </a>
                {/* <a href='#' className='cc-btn'>
                  <i
                    className='fa-regular fa-closed-captioning'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Turn on captions'></i>
                </a>
                <a href='#' className='smily-btn'>
                  <i
                    className='fa-regular fa-face-smile'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Send a reaction'></i>
                </a> */}
                <a href='#' className='screen-share-btn'>
                  <i
                    className='fa-solid fa-laptop'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Present now'></i>
                </a>
                {/* <a href='#' className='hand-btn'>
                  <i
                    className='fa-regular fa-hand'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Raise hand'></i>
                </a>
                <a href='#' className='more-btn'>
                  <i
                    className='fa-solid fa-ellipsis-vertical'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='More options'></i>
                </a> */}
                <a href='#' className='call-end-btn'>
                  <i
                    className='fa-solid fa-phone'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Leave call'
                    onClick={() => handleCancel()}></i>
                </a>
              </div>
            </div>
            <div className='video-chat-actions'>
              <a href='#'>
                <i
                  className='fa-solid fa-circle-info'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Meeting details'></i>
              </a>
              <a href='#'>
                <i
                  className='fa-solid fa-users'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Show everyone'></i>
                <span>{remoteUsers.length + 1}</span>
              </a>
              <a href='#'>
                <i
                  className='fa-solid fa-message'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Chat with everyone'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetHome;
