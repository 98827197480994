import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../scss/style.css";
import "../js/additional.js";
import meet_up_logo from "../assets/images/meet_up_logo.png";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import img_slide_01 from "../assets/images/img_slide_01.png";
import img_slide_02 from "../assets/images/img_slide_02.png";
import img_slide_03 from "../assets/images/img_slide_03.png";

const MeetLogin = () => {
  const navigate = useNavigate();

  const handleClickMeeting = () => {
    navigate(`/start`);
  };
  return (
    <div className='meet-up-home'>
      <div className='meet-up-header'>
        <div className='meet-up-header-inner'>
          <div className='meet-up-logo'>
            <img src={meet_up_logo} alt='img' />
            <h5>
              <strong>Meet</strong> Up
            </h5>
          </div>
          <div className='user-login'>
            <div className='user-login-assets'>
              <ul className='navi-bar'>
                <li className='data-time'>
                  <span>12:47 PM</span>
                  <span> • </span>
                  <span>Sat, Aug 3</span>
                </li>
                {/* <li>
                  <a href='#' className='rounded-circle'>
                    <i className='fa-regular fa-circle-question'></i>
                  </a>
                </li>
                <li>
                  <a href='#' className='rounded-circle'>
                    <i className='fa-solid fa-message'></i>
                  </a>
                </li>
                <li>
                  <a href='#' className='rounded-circle'>
                    <i className='fa-solid fa-gear'></i>
                  </a>
                </li>
                <li className='custom-dropdown ms-4'>
                  <div className='dropdown-end'>
                    <a className='dropdown-toggle' data-bs-toggle='dropdown'>
                      <img src={img_user_profile} alt='img' />
                    </a>
                    <ul className='dropdown-menu dropdown-menu-customize dropdown-menu-end'>
                      <li>
                        <a className='dropdown-item' href='#'>
                          Action
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='meet-up-content'>
        <div className='meet-up-inner-content'>
          <div className='row w-100'>
            <div className='meeting-code col-md-6'>
              <h1>Video calls and meetings for everyone</h1>
              <h3>
                Connect, collaborate, and celebrate from anywhere with{" "}
                <strong>Meet Up</strong>
              </h3>
              <div className='create-meeting'>
                <div className='dropdown-end'>
                  <a
                    className='dropdown-toggle primary-bg-color user-meeting-create'
                    data-bs-toggle='dropdown'>
                    <i className='fa-solid fa-user-plus'></i>
                    <span className='ms-2'>New Meeting</span>
                  </a>
                  <ul className='dropdown-menu dropdown-menu-customize'>
                    {/* <li>
                      <a className='dropdown-item' href='#'>
                        <i className='ms-2 me-3 fa-solid fa-link'></i> Create a
                        meeting for later
                      </a>
                    </li> */}
                    <li>
                      <p
                        className='dropdown-item'
                        onClick={() => handleClickMeeting()}>
                        <i className='ms-2 me-3 fa-solid fa-plus'></i> Start an
                        instant meeting
                      </p>
                    </li>
                    {/* <li>
                      <a className='dropdown-item' href='#'>
                        <i className='ms-2 me-3 fa-regular fa-calendar'></i>{" "}
                        Schedule in Google Calendar
                      </a>
                    </li> */}
                  </ul>
                </div>
                <form className='meeting-form'>
                  <div className='position-relative'>
                    <i className='fa-regular fa-keyboard'></i>
                    <input type='text' placeholder='Enter a code or link' />
                  </div>
                  <button type='button' className='disabled' disabled>
                    Join
                  </button>
                </form>
              </div>
            </div>
            <div className='meet-up-slider col-md-6'>
              <div className='meeting-sliders'>
                <div className='slider-content'>
                  <img src={img_slide_01} alt='img' />
                  <h4>Get a link you can share</h4>
                  <p>
                    Click <strong>New meeting</strong> to get a link you can
                    send to people you want to meet with
                  </p>
                </div>
                {/* <div className="slider-content">
                       <img src={img_slide_02} alt="img" />
                       <h4>Plan ahead</h4>
                       <p>Click <strong>New meeting</strong> to schedule meetings in Google Calendar and send invites to participants</p>
                     </div>
                     <div className="slider-content">
                       <img src={img_slide_03} alt="img" />
                       <h4>Your meeting is safe</h4>
                       <p>No one can join a meeting unless invited or admitted by the host</p>
                     </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetLogin;
