import React from "react";
import { useNavigate } from "react-router-dom";
import "../scss/style.css";
import "../js/additional.js";
// import meet_up_logo from "../assets/images/meet_up_logo.png";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import meet_up_logo from "../assets/images/meet_up_logo.png";
const MeetIn = () => {
  const navigate = useNavigate();
  const handleJoinMeeting = () => {
    navigate(`/home`);
  };

  return (
    <div className='meet-up-home'>
      <div className='meet-up-header'>
        <div className='meet-up-header-inner'>
          <div className='meet-up-logo'>
            <img src={meet_up_logo} alt='img' />
            <h5>
              <strong>Meet</strong> Up
            </h5>
          </div>
          <div className='user-login'>
            <div className='user-login-assets'>
              <ul className='navi-bar'>
                <li>
                  <a
                    href='#'
                    className='rounded-pill px-4 py-2 h-auto lh-base w-auto primary-bg-gradient'>
                    Sign in
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='meet-up-content'>
        <div className='meet-up-inner-content'>
          <div className='row w-100'>
            <div className='col-md-8'>
              <div className='video-chat'>
                <div className='video-chat'>
                  <div className='gradient-top'></div>
                  <div className='gradient-bottom'></div>
                  <div className='login-user-name'>Logged in user name</div>
                  <div className='open-close-camera'>
                    <span className='camera-start'>Camera is starting</span>
                    <span className='camera-off'>Camera is off</span>
                  </div>
                  <div className='tranparent-layer'></div>
                  <div className='meet-video-actions'>
                    <a href='#' className='mice-btn'>
                      <i className='mice-on fa-solid fa-microphone'></i>
                      <i className='mice-off fa-solid fa-microphone-slash'></i>
                    </a>
                    <a href='#' className='video-btn'>
                      <i className='video-on fa-solid fa-video'></i>
                      <i className='video-off fa-solid fa-video-slash'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='user-presentation'>
                <h2>Ready to join?</h2>
                <p>No one else is here</p>
                <button
                  type='button'
                  className='primary-bg-gradient'
                  onClick={() => handleJoinMeeting()}>
                  Join now
                </button>
                {/* <div className='external-links'>
                  <a href='#' className='primary-text-color'>
                    <i className='fa-solid fa-desktop me-2'></i> Present
                  </a>
                  <a href='#' className='primary-text-color'>
                    <i className='fa-solid fa-display me-2'></i> Use Companion
                    mode
                  </a>
                  <a href='#' className='primary-text-color'>
                    <i className='fa-brands fa-chromecast me-2'></i> Cast this
                    meeting
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetIn;
