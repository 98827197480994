import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraRTCProvider } from "agora-rtc-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

async function setupLocalTracks() {
  const [microphoneTrack, cameraTrack] =
    await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        AEC: true, // Echo cancellation
        ANS: true, // Noise suppression
        AGC: true, // Automatic gain control
      },
      {}
    );

  return { microphoneTrack, cameraTrack };
}

root.render(
  <React.StrictMode>
    <AgoraRTCProvider client={client} setupLocalTracks={setupLocalTracks}>
      <App />
    </AgoraRTCProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
